:root {
  --iconSize: 7rem;
  --fontSize: 1.4rem;
  --fontColor: #080850;
}

.titleCat {
  margin-left: auto;
  margin-bottom: 40px;
  font-size: 2.5rem;
  font-family: "Century Gothic";
  color: #000080;
  text-align: center;
  user-select: none;
}

.boldTitle {
  font-weight: bold;
  color: #000080;
  font-family: "Century Gothic";
  text-align: center;
  user-select: none;
  font-size: 40px;
  margin-left: 2%;
}

.contCatBt {
  padding: 10px;
}

.Categorias {
  border-bottom: 3px solid cyan;
  padding-bottom: 100px;
  /* padding-left: 70px;*/
  padding-right: 60px;
  margin-top: 2.5vh;
  margin-right: 1.1vh;
  margin-bottom: 2.5vh;
  user-select: none;
}

/*Colegios*/

.divColegios {
  display: inline-block;
  width: fit-content;
}

.categoria-icon {
  margin-top: 1em;
  width: var(--iconSize);
  cursor: pointer;
  padding: 0.em;
}

.categoria-icon:hover {
  transform: scale(1.1);
  background-color: #cff2f91d;
}

.categoria-icon:focus {
  transform: scale(1.2);
  background-color: rgba(var(--labelsDescription), 1);
}

.colegiosIcon {
  transform: translateX(7px);
  width: var(--iconSize);
  cursor: pointer;
}

.txtCategoria {
  font-size: var(--fontSize);
  cursor: pointer;
  color: var(--fontColor);
}

/*icono todos los jardin Infantil*/

.jardinInfantil {
  display: inline-block;
  width: fit-content;
}

.jardinIcon {
  width: var(--iconSize);
  cursor: pointer;
}

.txtJardin {
  user-select: none;
  font-size: var(--fontSize);
  text-align: center;
  text-decoration: none;
  display: flex;
  cursor: pointer;
  color: var(--fontColor);
}

/*Icono escuelas de lenguaje*/

.escuelaLenguaje {
  display: inline-block;
  width: fit-content;
}

.eLenguajeIcon {
  transform: translateX(20px);
  width: var(--iconSize);
  cursor: pointer;
  display: inline-block;
}

.txtELenguaje {
  margin-left: 16px;
  font-size: var(--fontSize);
  text-align: center;
  text-decoration: none;
  display: flex;
  cursor: pointer;
  color: var(--fontColor);
}

/*Icono escuelas Libres*/

.escuelasLibres {
  display: inline-block;
  width: fit-content;
}

.eLibresIcon {
  transform: translateX(15px);
  width: var(--iconSize);
  cursor: pointer;
  display: inline-block;
}

.txtELibres {
  font-size: var(--fontSize);
  text-align: center;
  text-decoration: none;
  display: flex;
  cursor: pointer;
  color: var(--fontColor);
}

/*Icono Colegios Online*/

.colegiosOnline {
  display: inline-block;
  width: fit-content;
  margin-bottom: 2em;
}

.cOnline {
  transform: translateX(15px);
  width: var(--iconSize);
  cursor: pointer;
  display: inline-block;
  color: var(--fontColor);
}

.txtCOnline {
  margin-left: 14px;
  font-size: var(--fontSize);
  text-align: center;
  text-decoration: none;
  display: flex;
  cursor: pointer;
  color: var(--fontColor);
}

/*Icono Academias Deportivas*/

.academiasDeportivas {
  display: inline-block;
  width: fit-content;
}

.aDeportiva {
  transform: translateX(15px);
  width: var(--iconSize);
  cursor: pointer;
  display: inline-block;
}

.txtADeportiva {
  margin-left: 16px;
  font-size: var(--fontSize);
  text-align: center;
  text-decoration: none;
  display: flex;
  cursor: pointer;
  color: var(--fontColor);
}

/*Icono Escuelas de idiomas*/

.escuelasDeIdiomas {
  display: inline-block;
  width: fit-content;
}

.eidiomas {
  transform: translateX(15px);
  width: var(--iconSize);
  cursor: pointer;
  display: inline-block;
}

.txtEIdiomas {
  margin-left: 6px;
  font-size: var(--fontSize);
  text-align: center;
  text-decoration: none;
  display: flex;
  cursor: pointer;
  color: var(--fontColor);
}

/*Icono Preuniversitario*/

.preuniversitario {
  display: inline-block;
  width: fit-content;
}

.preUni {
  transform: translateX(10px);
  width: var(--iconSize);
  cursor: pointer;
  display: inline-block;
}

.txtPreUni {
  font-size: var(--fontSize);
  text-align: center;
  text-decoration: none;
  display: flex;
  cursor: pointer;
  color: var(--fontColor);
}

/*Icono escuela de verano*/

.EscuelaDeVerano {
  display: inline-block;
  width: fit-content;
}

.eVerano {
  transform: translateX(10px);
  width: var(--iconSize);
  cursor: pointer;
  display: inline-block;
}

.txteVerano {
  margin-left: 14px;
  font-size: var(--fontSize);
  text-align: center;
  text-decoration: none;
  display: flex;
  cursor: pointer;
  color: var(--fontColor);
}

/*Icono Guardería*/

.guarderiaInfantil {
  display: inline-block;
  width: fit-content;
}

.gInfantil {
  transform: translateX(10px);
  width: var(--iconSize);
  cursor: pointer;
  display: inline-block;
}

.txtgInfantil {
  margin-left: 16px;
  font-size: var(--fontSize);
  text-align: center;
  text-decoration: none;
  display: flex;
  cursor: pointer;
  color: var(--fontColor);
}

/*Icono Academia Artistica*/

.academiaArtistica {
  display: inline-block;
  width: fit-content;
}

.aArtistica {
  transform: translateX(20px);
  width: var(--iconSize);
  cursor: pointer;
  display: inline-block;
}

.txtaArtistica {
  margin-left: 16px;
  font-size: var(--fontSize);
  text-align: center;
  color: var(--fontColor);
  text-decoration: none;
  display: flex;
  cursor: pointer;
}

/*Icono Reforzamiento*/

.reforzamiento {
  margin-top: 20px;
  display: inline-block;
  width: fit-content;
}

.iReforzamiento {
  transform: translateX(20px);
  width: var(--iconSize);
  cursor: pointer;
  display: inline-block;
}

.txtReforzamiento {
  margin-left: 6px;
  color: var(--fontColor);
  font-size: var(--fontSize);
  text-align: center;
  text-decoration: none;
  display: flex;
  cursor: pointer;
}

@media (max-width: 1367px) {
  :root {
    --iconSize: 5.5rem;
    --fontSize: 1.1rem;
  }

  .titleCat {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;
    font-size: 3rem;
    font-family: "Century Gothic";
    color: #000080;
    text-align: center;
    user-select: none;
  }

  .txtJardin {
    margin-left: 25px;
    user-select: none;
    font-size: var(--fontSize);
    text-align: center;
    text-decoration: none;
    display: flex;
    cursor: pointer;
  }

  .txtELenguaje {
    margin-left: 27px;
    font-size: var(--fontSize);
    text-align: center;
    text-decoration: none;
    display: flex;
    cursor: pointer;
  }

  .txtELibres {
    margin-left: 25px;
    font-size: var(--fontSize);
    text-align: center;
    text-decoration: none;
    display: flex;
    cursor: pointer;
  }

  .txtCOnline {
    margin-left: 24px;
    font-size: var(--fontSize);
    text-align: center;
    text-decoration: none;
    display: flex;
    cursor: pointer;
  }

  .eidiomas {
    transform: translateX(1px);
    width: var(--iconSize);
    cursor: pointer;
    display: inline-block;
  }

  .txtEIdiomas {
    margin-left: 1px;
    font-size: var(--fontSize);
    text-align: center;
    text-decoration: none;
    display: flex;
    cursor: pointer;
  }
}

@media (max-width: 426px) {
  .titleCat {
    margin-left: auto;
    margin-bottom: 40px;
    font-size: 2rem;
    font-family: "Century Gothic";
    color: #000080;
    text-align: center;
    user-select: none;
  }
}
@media (max-width: 768px) {
}
@media (max-width: 376px) {
}

@media (max-width: 321px) {
}
