.firstCircle {
  background-color: #00ccff;
  height: 30rem;
  width: 30rem;
  border-radius: 30em;
  margin: auto;
}

.secondCircle {
  background-color: #fd5db8;
  height: 30rem;
  width: 30rem;
  border-radius: 30em;
  margin: auto;
}

.thirdCircle {
  background-color: #33cc33;
  height: 30rem;
  width: 30rem;
  border-radius: 30em;
  margin: auto;
}

.fourthCircle {
  background-color: #4472c4;
  height: 30rem;
  width: 30rem;
  border-radius: 30em;
  margin: auto;
}

.fifthCircle {
  background-color: #ffc000;
  height: 30rem;
  width: 30rem;
  border-radius: 30em;
  margin: auto;
}

.Image {
  height: 30rem;
  width: 30rem;
  padding: 40px;
  border-radius: 600px;
  object-fit: cover;
  margin-left: 12%;
  margin: auto;
  display: block;
}


.txtImages {
  margin-left: 2%;
  font-size: 2.5rem;
  font-family: "Century Gothic";
  color: #000080;
  text-align: center;
  user-select: none;
}

.contImagesInfo {
  margin-bottom: 50px;
}

.txtImages1 {
  margin-left: 2%;
  font-size: 2rem;
  font-family: "Century Gothic";
  color: #000080;
  text-align: center;
  user-select: none;
}

@media (max-width: 1025px) {
  .firstCircle {
    background-color: #00ccff;
    height: 23rem;
    width: 23rem;
    border-radius: 30em;
    margin: auto;
  }

  .secondCircle {
    background-color: #fd5db8;
    height: 23rem;
    width: 23rem;
    border-radius: 30em;
    margin: auto;
  }

  .thirdCircle {
    background-color: #33cc33;
    height: 23rem;
    width: 23rem;
    border-radius: 30em;
    margin: auto;
  }

  .fourthCircle {
    background-color: #4472c4;
    height: 23rem;
    width: 23rem;
    border-radius: 30em;
    margin: auto;
  }

  .fifthCircle {
    background-color: #ffc000;
    height: 23rem;
    width: 23rem;
    border-radius: 30em;
    margin: auto;
  }

  .Image {
    height: 23rem;
    width: 23rem;
    padding: 40px;
    border-radius: 600px;
    object-fit: cover;
    margin-left: 12%;
    margin: auto;
    display: block;
  }

  .txtImages {
    margin-left: 2%;
    font-size: 1.5rem;
    font-family: "Century Gothic";
    color: #000080;
    text-align: center;
    user-select: none;
  }

  .txtImages1 {
    margin-left: 2%;
    font-size: 1rem;
    font-family: "Century Gothic";
    color: #000080;
    text-align: center;
    user-select: none;
  }
}


@media (max-width: 1367px){
  .txtImages {
    margin-left: 2%;
    font-size: 3rem;
    font-family: "Century Gothic";
    color: #000080;
    text-align: center;
    user-select: none;
  }

  .txtImages1 {
    margin-left: 2%;
    font-size: 2.5rem;
    font-family: "Century Gothic";
    color: #000080;
    text-align: center;
    user-select: none;
  }
 
}
