.ocurrence-square {
  height: 3em;
  width: 3em;
  background-color: var(--labelsDescriptionStrong);
  border: 1px solid black;
  cursor: pointer;
  border-radius: 10px;
}

.ocurrence-square-hover {
  height: 3em;
  width: 3em;
  background-color: var(--labelsDescription);
  border: 1px solid black;
  cursor: pointer;
  border-radius: 10px;
}

.ocurrence-square-empty {
  height: 3em;
  width: 3em;
  background-color: white;
  border: 1px solid black;
  cursor: pointer;
  border-radius: 10px;
}

.ocurrence-square-used {
  height: 3em;
  width: 3em;
  background-color: rgb(238, 147, 147);
  border: 1px solid black;
  border-radius: 10px;
}

.percentage-label {
  position: absolute;
  top: "50%";
  left: "50%";
}
