.image-wrapper {
  width: 400px;
  height: 400px;
  overflow: hidden;
  position: relative;
  background: rgba(0, 0, 0, 0.5);
  margin: 10px 0;
}
.image-wrapper img {
  width: 100%;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}