/* @main-color: #4a9bdc;

.main-color {
  color: @main-color;
} */

.frosted-glass-background {
  /* -webkit-backdrop-filter: blur(8px);  */
  /* backdrop-filter: blur(8px);  */
  box-shadow: 0px 1px 5px 1px rgb(0 0 0 / 15%);
  /* background-color: rgb(228 228 228 / 15%);  */
  background-color: white;
}

.frosted-glass-background:hover {
  /* -webkit-backdrop-filter: blur(8px);  */
  /* backdrop-filter: blur(8px);  */
  box-shadow: 0px 2px 2px 2px rgb(0 0 0 / 15%);
  /* background-color: rgb(228 228 228 / 40%); */
  background-color: white;
}

.frosted-glass-background-strong {
  -webkit-backdrop-filter: blur(8px); /* Safari 9+ */
  backdrop-filter: blur(8px); /* Chrome and Opera */
  box-shadow: 0px 10px 15px 10px rgb(0 0 0 / 30%);
  background-color: rgb(228 228 228 / 15%);
}

.frosted-glass-background-strong:hover {
  /* -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px); 
  box-shadow: 0px 10px 15px 10px rgb(0 0 0 / 50%);
  background-color: rgb(228 228 228 / 40%); */
  background-color: white;
}

.frosted-glass-background-no-hover {
  -webkit-backdrop-filter: blur(8px); /* Safari 9+ */
  backdrop-filter: blur(8px); /* Chrome and Opera */
  box-shadow: 0px 10px 15px 10px rgb(0 0 0 / 15%);
  background-color: rgba(241, 241, 241, 0.527);
}

.image-gallery {
  width: 100vw;
  height: auto;
}

.image-gallery-slide img {
  width: 30vw;
  height: auto;
  max-height: 80vh;
  object-fit: cover;
  overflow: hidden;
  object-position: center center;
}

/* .ant-list-header {
  background-color: var(--labelsDescription) !important;
} */

.ant-descriptions-bordered.ant-descriptions-small .ant-descriptions-item-label,
.ant-descriptions-bordered.ant-descriptions-small
  .ant-descriptions-item-content {
  padding: 4px 6px !important;
}

.ant-table-thead > tr > th {
  background-color: var(--labelsDescription) !important;
}

.fullscreen .image-gallery-slide img {
  max-height: 100vh;
}

.cursor-pointer {
  cursor: "pointer";
}

th.ant-descriptions-item-label {
  font-weight: bold;
  font-size: 14px;
  line-height: 1.5715;
  text-align: start;
  background-color: red;
}

.ant-descriptions-item-label {
  background-color: var(--labelsDescription) !important;
}

ant-card-body > .ant-descriptions-bordered .ant-descriptions-item-label {
  background-color: rgba(0, 255, 255, 0.192) !important;
}

@media (min-width: 1300px) and (max-width: 1600px) {
  th.ant-descriptions-item-label {
    font-size: 11px;
  }
  .ant-descriptions-item-content {
    font-size: 11px !important;
  }
}

.small-card-body-padding .ant-card-body {
  padding-left: 0.3em;
  padding-right: 0.3em;
  padding-top: 1em;
}

.ant-layout-header {
  border-bottom-width: 1px;
  border-bottom-color: var(--labelsDescriptionStrong);
  border-bottom-style: solid;
}

.appContent {
  min-height: 100vh;
}

#appContent {
  min-height: 100vh;
}

@supports (-webkit-touch-callout: none) {
  html {
    position: fixed;
    height: 100%;
    overflow: hidden;
  }
}

body {
  width: 100vw;
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  /* -webkit-overflow-scrolling: touch; */
}

.ct-row {
  position: sticky!important;
  top: 0px!important;
  z-index: 100!important;
}

@media screen and (min-width: 2560px) {
  .appContent {
    padding-left: 30em;
    padding-right: 30em;
  }

  .header-sticky {
    padding-right: 30em;
  }
}    
