:root{
  --shapeColor: #4dffff8c;
}

.banner {
  display: inline-block;
}


#bannerImage {
  border-top: 3px solid cyan;
  width: 100%;
  height: 100%;
  border-bottom: 3px solid cyan;
}

#logoBanner {
  z-index: 1;
  /* position: absolute; */
  width: 25vw;
  height: auto;
  transform: translateX(67vw);
  margin-top: 23%;
}

h1 {
  /* position: absolute; */
  z-index: 1;
  transform: translateX(35vw);
  font-size: 2.3vw;
  text-align: center;
  font-family: "Noto Serif", serif;
  user-select: none;
}

/* circles animations */

.circles {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 68vh;
	z-index: 5;
}

.circles li {
	position: absolute;
	display: block;
	list-style: none;
	width: 20px;
	height: 20px;
	background: var(--shapeColor);
	animation: animate 25s linear infinite;
	bottom: calc(-150px - 40vh);
}

.circles li:nth-child(1) {
	left: 25%;
	width: 80px;
	height: 80px;
	animation-delay: 0s;
	animation-duration: 20s;
	border-radius: 50%;
	border-top-left-radius: 0 !important;
	background: var(--shapeColor);
}

.circles li:nth-child(2) {
	left: 10%;
	width: 20px;
	height: 20px;
	animation-delay: 0s;
	animation-duration: 25s;
	border-radius: 50%;
	border-top-left-radius: 0 !important;
	background: var(--shapeColor);
}

.circles li:nth-child(3) {
	left: 70%;
	width: 20px;
	height: 20px;
	animation-delay: 0s;
	animation-duration: 20s;
	border-radius: 50%;
	border-top-left-radius: 0 !important;
	background: var(--shapeColor);
}

.circles li:nth-child(4) {
	left: 40%;
	width: 60px;
	height: 60px;
	animation-delay: 0s;
	animation-duration: 15s;
	border-radius: 50%;
	border-top-left-radius: 0 !important;
	background: var(--shapeColor);
}

.circles li:nth-child(5) {
	left: 65%;
	width: 20px;
	height: 20px;
	animation-delay: 0s;
	animation-duration: 20s;
	border-radius: 50%;
	border-top-left-radius: 0 !important;
	background: var(--shapeColor);
}

.circles li:nth-child(6) {
	left: 75%;
	width: 90px;
	height: 90px;
	animation-delay: 0s;
	animation-duration: 25s;
	border-radius: 50%;
	border-top-left-radius: 0 !important;
	background: var(--shapeColor);
}

.circles li:nth-child(7) {
	left: 35%;
	width: 50px;
	height: 50px;
	animation-delay: 0s;
	animation-duration: 30s;
	border-radius: 50%;
	border-top-left-radius: 0 !important;
	background: var(--shapeColor);
}

.circles li:nth-child(8) {
	left: 50%;
	width: 25px;
	height: 25px;
	animation-delay: 0s;
	animation-duration: 35s;
	border-radius: 50%;
	border-top-left-radius: 0 !important;
	background: var(--shapeColor);
}

.circles li:nth-child(9) {
	left: 20%;
	width: 15px;
	height: 15px;
	animation-delay: 0s;
	animation-duration: 40s;
	border-radius: 50%;
	border-top-left-radius: 0 !important;
	background: var(--shapeColor);
}

.circles li:nth-child(10) {
	left: 85%;
	width: 50px;
	height: 50px;
	animation-delay: 0s;
	animation-duration: 45s;
	border-radius: 50%;
	border-top-left-radius: 0 !important;
	background: var(--shapeColor);
}

@keyframes animate {
	0% {
		transform: translateY(-50vh) rotate(0deg);
		opacity: 0;
	}
	50% {
		transform: translateY(-80vh) rotate(360deg);
		opacity: 1;
	}
	100% {
		transform: translateY(-120vh) rotate(720deg);
		opacity: 0;
	}
}

