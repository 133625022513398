/* .slide.previous{
  min-width: 18%!important;
}
.slide.selected{
  min-width: 18%!important;
} */
/* 
.slide{
  min-width: 18%!important;
} */


.alice-carousel__stage-item.__active.__target{
  width: 240px !important;
}
.alice-carousel__stage-item{
  width: 240px !important;
  padding-left: 20!important;
  padding-right: 20!important;
}




