@import "/src/colorPalette.css";

.siderLista {
  background-color: var(--pencilBK) !important;
}

.SpaceConfig {
  -webkit-box-shadow: 0px 10px 13px -7px #000000,
    6px 5px 15px 37px rgba(0, 0, 0, 0);
  box-shadow: 0px 10px 13px -7px #000000, 6px 5px 15px 37px rgba(0, 0, 0, 0);
}

.tarjetasInstituciones {
  color: blue !important;
}

h5.ant-typography,
.ant-typography h5 {
  margin-bottom: 0.5em;
  font-weight: 600;
  font-size: 16px !important;
  line-height: 1.5;
}
