.filters-row:hover {
  cursor: pointer;
  background: rgba(164, 207, 213, 0.31);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(9.6px);
  -webkit-backdrop-filter: blur(9.6px);
  border: 1px solid rgba(118, 193, 203, 0.3);
}

.selected-filter {
  /* From https://css.glass */
  background: rgba(39, 197, 205, 0.31);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(149, 183, 228, 0.1);
  backdrop-filter: blur(9.6px);
  -webkit-backdrop-filter: blur(9.6px);
  padding-left: 1em;
}

.horizontal-container {
  max-height: 400px;
  padding: 1em;
  overflow-y: auto;
}

.ant-list {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: relative;
}