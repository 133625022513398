:root{
    --papaya: #E24E42;
    --mustard: #E9B000;
    --blush: #EB6E80;
    --aqua:#07adb3;
    --pencilBK: #05C7F2;
    --headerHeight: 80

    /* alternate color palletee */
    --pencilBK2: #E3B409;
    --pencilBK3: #FAC402;
    --pencilBK4: #E3B409;
    --pencilBK5: #FDC80A;
    --aquaBK2: #6debf0;
    --labelsDescription: #cff2f9;
    --labelsDescriptionStrong: #24cdee;
}

html{
    scroll-behavior: smooth;
}