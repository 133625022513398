.LogoColegio {
  width: 40vh;
  display: inline-block;
  margin-left: 77vh;
  margin-right: 65vh;
  margin-top: 5vh;
}

.content {
  background-color: white;
}

.barCatColegios {
  background-color: blueviolet;
  color: white;
  padding-left: 5vh;
  margin-bottom: 3.5vh;
}

.etiquetaCole {
  margin-left: 85vh;
  font-size: 5vh;
  color: red;
}
