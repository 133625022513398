.formularios {
  border: 2px solid red;
  margin-left: 30vh;
  margin-right: 30vh;
}

h1 {
  margin-right: 20vh;
}

.pagination {
  padding-top: 2vh;
  padding-left: 30%;
  margin-left: 30vh;
  margin-right: 30vh;
  border: 2px solid red;
}

.contFormReg {
  margin-top: 40px;
  margin-bottom: 21.7vh;
}

.formReg {
  background-color: #fac402 !important;
}

.btRegistro {
  background-color: #4dff4d !important;
}

body {
  background-color: rgb(255, 255, 255);
  backdrop-filter: blur(9.1px);
  -webkit-backdrop-filter: blur(9.1px);
}

.login-text {
  color: rgb(148, 46, 46);
  font-size: 4em;
}


.imgRegistro{
  margin-top: 36px;
  margin-left: 26px;
  width: 100%;
  height: auto;
  opacity: 75%;
}