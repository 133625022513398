@import "/src/colorPalette.css";

/* .filters-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0;
  color: white !important;
} */

.typeSelectorEd {
  width: 100% !important;
}

.ant-btn{
  border-radius: 20px !important;
}


.ant-btn-primary {
  color: rgb(255, 255, 255) !important;
  border-radius: 20px !important;
  background: var(--aqua) !important;
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
  box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
}

/* .ant-list {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgb(255 255 255) !important;
  font-size: 16px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: relative;
} */

.dropMenusBusqueda {
  border: solid 3px cyan;
  border-radius: 18px;
  margin-left: 4em;
  margin-right: 4em;
  padding-bottom: 40px;
  padding-top: 10px;
}

.labels{
  font-family: "Century Gothic";
  color: #000080;
}

.contBuscadorLocCol {
  /* height: 100vh; */
  color: black;
}

.scrollDown {
  font-size: 90px;
  text-align: center;
  margin-left: 47%;
}

.titleBusLoc {
  margin-left: 2%;
  margin-top: 10vh;
  margin-bottom: 10vh;
  font-size: 2.5em;
  text-align: center;
  font-family: "Century Gothic";
  color: #000080;
  user-select: none;
}

label {
  user-select: none;
  font-size: 25px;
  font-weight: bold;
}

.tipoEstablecimiento {
  width: 100%;
}

.region {
  width: 100%;
}
.comuna {
  width: 100%;
}

.filtrosCascade {
  width: 100%;
}

.filtrosIcon {
  text-align: center;
  color: red !important;
  font-size: 45px;
}

.botonBuscar1 {
  width: 90%;
  height: 60%;
  margin-top: 10px;
  border-radius: 18px;
  font-weight: bold;
  font-size: larger;
}

.regionSelector {
  width: 195px;
}
