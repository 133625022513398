p {
  float: left;
}

.tituloLogin {
  text-align: center;
}

.App-logo2 {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 60%;
  height: auto;
}

.btnVolver {
  font-size: large;
  font-weight: bold;
  margin: 1vh;
}

.formularioLogin {
  margin-left: 100px;
  margin-right: auto;
}

.imgLogin {
  width: 100%;
  height: auto;
  opacity: 75%;
}

.form-login {
  margin-left: auto;
  margin-right: auto;
  width: 60%;
  height: calc(100vh - var(--headerHeight));
  -webkit-backdrop-filter: blur(8px); /* Safari 9+ */
  backdrop-filter: blur(8px); /* Chrome and Opera */
  box-shadow: 0px 10px 15px 10px rgb(0 0 0 / 15%);
  background-color: rgba(241, 241, 241, 0.527);
}



/* @media (max-width: 1600px) {
  .form-login {
    height: 80vh;
  }
}

@media (max-width: 1440px) {
  .form-login {
    height: 70vh;
  }
}

@media (max-width: 1024px) {
  .form-login {
    height: 130vh;
  }
}

@media (max-width: 768px) {
  .form-login {
    height: 70vh;
  }
} */