@import "/src/colorPalette.css";

header.ant-layout-header {
  height: 80px;
  padding: 0 30px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 80px;
  background: rgb(255, 255, 255);
  /* position: sticky !important; */
}

.searchbarItems {
  padding: 0.5em;
}

.Logotipo {
  margin: 1rem;
  width: 15rem;
  height: fit-content;
}

#btLogin {
  margin-top: 2.6em;
  border-radius: 20px;
  color: white;
  background: #0077ff;
  width: 100%;
}

#btReg {
  margin-top: 2.6em;
  border-radius: 20px;
  border: 1px solid #00a2ff;
  margin-left: 10px;
  background-color: white;
  color: #00a2ff;
}

#btPer {
  margin-left: 10px;
  font-weight: bold;
  border: 1px solid black;
  background: white;
}

.headerSearchBar
  .ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  border-radius: 30px !important;
}

.logotipo-pagina {
  margin-bottom: 1em;
  margin-top: 0.5em;
  height: 5em;
  width: auto;
}

@media (max-width: 426px) {
  .logotipo-pagina {
    margin-bottom: 1em;
    margin-top: 0.5em;
    height: 5em;
    width: auto;
  }
}
