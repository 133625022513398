@import "/src/colorPalette.css";

.contenedorBarrasDebusqueda {
  border: solid 5px cyan;
  border-radius: 20px;
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 50px;
  padding: 15px;
  padding-left: 3em;
}

.labels{
  font-family: "Century Gothic";
  color: #000080;
}

.contbusquedas {
  padding-top: 20px;
  padding-bottom: 20px;
  height: 100vh;
  background-color: #ffffff00;
}

.titleBusNom {
  margin-left: 2%;
  margin-bottom: 5%;
  font-size: 2.5em;
  text-align: center;
  font-family: "Century Gothic";
  color: #000080;
  user-select: none;
}

.iconButton{
  transform: translateY(10px);
  width: 50px !important;
  height: 50px !important;
  border-radius: 600px;
}

@media (max-width: 376px){
  .botonBuscar{
    transform: translateX(5.5em);
  }
}