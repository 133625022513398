@import "/src/colorPalette.css";

.ins-edu-detail-block {
  padding: 1em;
  /* background-color:whitesmoke; */
  /* border: 2px solid rgb(51, 153, 255); */
  margin-right: 2em;
  margin-left: 1em;
}

.detalleCol {
  background-color: white;
}

.cuadroContacto {
  border: solid 3px blue;
}

.cuadroDescripcion {
  border: solid 3px blue;
}

.cuadroDescripcion {
  border: solid 3px blue;
}

.ins-edu-detail-gallery {
  border: 1px solid blue;
  border-radius: 10px;
  margin-right: 2em;
  margin-left: 1em;
}

.blue-main-text {
  color: rgb(51, 153, 255);
  font-weight: bold;
}

.youtube-embed {
  width: 100%;
  height: 100%;
}

.insEduContactInfo {
  /* padding: 1em; */
  padding-right: 2em;
  /* border: 2px solid rgb(51, 153, 255); */
  margin-right: 2em;
  margin-left: 1em;
}
