.headerContainter {
  height: 3em;
  background-color: white;
}
.App-logo {
  cursor: pointer;
}

.logo {
  border: solid 2px red;
}

.logoUser {
  transform: translateX(5em);
  text-align: right;
  font-size: 7em;
  transform: scale(0.5);
}

p {
  display: inline-block;
  text-align: center;
}

.logoTextoHeader {
  display: inline-block;
}

.botonBuscar1Header {
  transform: translateY(20px);
}

@media (max-width: 1440px) {
  .App-logo {
    margin-top: 2em;
    cursor: pointer;
  }

  .logoUser {
    margin-top: 5px;
    transform: translateX(0.3em);
    text-align: right;
    font-size: 65px;
  }

  p {
    display: inline-block;
    text-align: center;
  }

  .logoTextoHeader {
    display: inline-block;
  }

  .botonBuscar1Header {
    transform: translateY(20px);
  }
}

@media (max-width: 1024px) {
  .App-logo {
    cursor: pointer;
    margin: 4vh;
  }

  .logoUser {
    margin-top: 6px;
    display: inline-block;
    margin-left: 0.3em;
    text-align: right;
    font-size: 3rem;
  }

  p {
    font-size: 1rem;
    display: inline-block;
    text-align: center;
  }

  .logoTextoHeader {
    display: inline-block;
  }

  .columna1 {
    display: inline-block;
  }

  .botonBuscar1Header {
    transform: translateY(20px);
  }
}

@media (max-width: 768px) {
  .App-logo {
    cursor: pointer;
    margin-left: 0vh;
  }

  .logoUser {
    margin-top: 6px;
    margin-left: 0.7em;
    text-align: left;
    font-size: 2rem;
  }

  p {
    margin-left: 0.05em;
    display: inline-block;
    text-align: center;
  }

  .logoTextoHeader {
    display: inline-block;
  }
}

@media (max-width: 426px) {
  .App-logo {
    cursor: pointer;
    margin: 0%;
  }

  .logoUser {
    display: inline-block;
    text-align: left;
    font-size: 1.4rem;
  }

  p {
    visibility: hidden;
    display: none;
    display: inline-block;
    font-size: 1px;
  }

  .logoTextoHeader {
    display: inline-block;
  }
}

@media (max-width: 321px) {
  .App-logo {
    cursor: pointer;
    margin: 0%;
  }

  .logoUser {
    text-align: left;
    font-size: 1.4rem;
  }

  p {
    font-size: 0px;
    visibility: hidden;
    display: none;
    display: inline-block;
  }

  .logoTextoHeader {
    display: inline-block;
  }
  .columna1 {
    visibility: hidden;
  }
  .columna2 {
    visibility: hidden;
  }
}
