@import "/src/colorPalette.css";

:root {
  --heightBase: 23rem;
}

/* #tituloOpciones {
  position: relative;
  font-size: 4vw;
  font-weight: bold;
  font-family: "Fredoka", sans-serif;
  transform: translateX(0);
  text-align: center;
  user-select: none;
} */

#dividerBar {
  background-color: aqua;
  height: 15px;
  width: 100%;
}

.txtTarjetasBusqueda {
  margin-top: 30px;
  /* margin-left: 20%;
  padding-left: 20%; */
  font-size: 2.5rem;
  font-family: "Century Gothic";
  color: #000080;
  text-align: center;
  user-select: none;
}

#tituloOpciones {
  font-size: 30rem;
  margin-bottom: 0px;
  font-weight: bold;
  background: linear-gradient(to right, var(--pencilBK), #fb3182);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  /* max-width: 350px; */
  text-transform: uppercase;
  line-height: 0.77;
}

#contTarjetas {
  margin: 1%;
  background-color: rgba(255, 255, 255, 0);
  border-bottom: 3px solid cyan;
  padding-bottom: 60px;
}

.cardsOptions {
  padding-left: 15%;
  margin-top: 100px;
}

#cardIco {
  margin: auto;
  font-size: 10rem;
}
.colCards{
  margin-right: 50px;
}

#tarjetaBusLoc {
  border: 5px solid var(--aqua);
  height: var(--heightBase);
  border-radius: 500px;
  text-align: center;
  width: 23rem;
}

#tarjetaBusNom {
  border: 5px solid var(--blush);
  height: var(--heightBase);
  border-radius: 500px;
  text-align: center;
  width: 23rem;
}

#tarjetaBusDirect {
  border: solid 5px var(--mustard);
  height: var(--heightBase);
  border-radius: 500px;
  text-align: center;
  width: 23rem;
}

.ant-card-meta-title {
  color: black !important;
  overflow: hidden;
  font-weight: bold !important;
  font-size: 1.3rem !important;
  text-align: center !important;
  white-space: nowrap;
  text-overflow: ellipsis;
  user-select: none;
}

.ant-card-meta-description {
  color: rgba(22, 22, 22, 0.89) !important;
  font-size: 1rem;
  user-select: none;
  padding-left: 55px;
  padding-right: 55px;
}

.buscadores {
  height: auto;
}
@media (max-width: 1601px){
  .colCards{
    margin-right: 10%;
  }

  .txtTarjetasBusqueda {
    margin-top: 30px;
    margin-left: 10%;
    font-size: 3rem;
    font-family: "Century Gothic";
    color: #000080;
    text-align: center;
    user-select: none;
  }

  .cardsOptions {
    padding-left: 15%;
    margin-top: 40px;
  }
}

@media (max-width: 1441px){
  .colCards{
    margin-right: 10%;
  }

  .txtTarjetasBusqueda {
    margin-top: 30px;
    margin-left: 10%;
    font-size: 3rem;
    font-family: "Century Gothic";
    color: #000080;
    text-align: center;
    user-select: none;
  }

  .cardsOptions {
    padding-left: 10%;
    margin-top: 40px;
  }
}

@media (max-width: 1367px){
  .colCards{
    margin-right: 10%;
  }

  .txtTarjetasBusqueda {
    margin-top: 30px;
    margin-left: 10%;
    font-size: 3rem;
    font-family: "Century Gothic";
    color: #000080;
    text-align: center;
    user-select: none;
  }

  .cardsOptions {
    padding-left: 7%;
    margin-top: 40px;
  }
}

@media (max-width: 768px){
  .colCards{
    margin-right: 1%;
  }

    .txtTarjetasBusqueda {
      margin-top: 30px;
      margin-left: 2%;
      font-size: 1.8rem;
      font-family: "Century Gothic";
      color: #000080;
      text-align: center;
      user-select: none;
    }

    .cardsOptions {
      padding-left: 7%;
      margin-top: 40px;
    }
}

@media (max-width: 426px){
  .colCards{
    margin-right: 1%;
  }

    .txtTarjetasBusqueda {
      margin-top: 30px;
      margin-left: 2%;
      font-size: 1.8rem;
      font-family: "Century Gothic";
      color: #000080;
      text-align: center;
      user-select: none;
    }

    .cardsOptions {
      padding-left: 7%;
      margin-top: 40px;
    }
}

@media (max-width: 394px){
  .colCards{
    margin-right: 1%;
  }

  .txtTarjetasBusqueda {
    margin-top: 30px;
    font-size: 2rem;
    font-family: "Century Gothic";
    color: #000080;
    text-align: center;
    user-select: none;
  }
  .cardsOptions {
    padding-left: 3%;
    margin-top: 40px;
  }
}

